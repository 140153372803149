import React, { useEffect, useState } from 'react'

import StadiumBody from 'components/stadium/stadiumBody'
import { graphql } from 'gatsby'
import { Layout, SubHeader } from 'components/global'

const VenueInfo = ({ data }) => {
  const stadiums = data.venues.nodes
  const metaData = {
    title: 'Venue Info | Arnold Clark Cup',
  }
  return (
    <Layout title={metaData.title} $noAnimation>
      <SubHeader title="Venue Info" />
      <StadiumBody data={stadiums} />
    </Layout>
  )
}

export default VenueInfo

export const VenueQuery = graphql`
  query VenueQuery {
    venues: allStadiumsJson {
      nodes {
        name
        index
        mainImage {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        map {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        links {
          title
          url
        }
      }
    }
  }
`
