import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { media } from 'utils/media'
import YellowX from 'svgs/yellowx.svg'
import Stripes from 'svgs/stripes.svg'
import GetImage from 'components/functional/getImage'
import { Link } from 'gatsby'
import { MaxWidth } from 'components/global'
import ArrowIcon from 'svgs/chevron.svg'
import StadiumPopUp from './popUp'

const FixBody = styled.section`
  width: 100%;
  background-color: var(--offWhite);
  position: relative;
  overflow-x: hidden;
  #yellowX {
    position: absolute;
    top: 1rem;
    right: -5rem;
    width: 12rem;
    z-index: 0;
    @media ${media.ls} {
      right: -2rem;
    }
    @media ${media.sm} {
      width: 13rem;
      right: 0rem;
      top: 0.5rem;
    }
    @media ${media.lg} {
      right: 2rem;
      top: 2rem;
      width: 14rem;
    }
    @media ${media.xl} {
      right: 6rem;
    }
  }
  #stripes {
    position: absolute;
    bottom: 0rem;
    left: -25rem;
    width: 35rem;
    @media ${media.ls} {
      width: 35rem;
      left: -20rem;
    }
    @media ${media.sm} {
      width: 35rem;
      left: -20rem;
    }
    @media ${media.lg} {
      width: 60rem;
      left: -35rem;
    }
  }

  .fixtures {
    position: relative;
    z-index: 5;
    width: 90%;
    margin: var(--auto);
    max-width: 1000px;
    padding: 2.5rem 0;
    color: var(--darkGrey);
    h1 {
      text-align: center;
    }
    p {
      width: 70%;
      margin: 1rem auto;
      text-align: center;
    }
    .grid {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
      @media ${media.sm} {
        grid-template-columns: 1fr 1fr;
      }
      > div {
        &:nth-child(1) {
          .overlay {
            background-color: var(--tealO);
          }
        }
        &:nth-child(2) {
          .overlay {
            background-color: var(--purpleO);
          }
        }
        &:nth-child(3) {
          .overlay {
            background-color: var(--blueO);
          }
        }
        &:nth-child(4) {
          .overlay {
            background-color: var(--pinkO);
          }
        }
        &:nth-child(5) {
          .overlay {
            background-color: var(--purpleO);
          }
        }
        &:nth-child(6) {
          .overlay {
            background-color: var(--blueO);
          }
        }
      }
    }
  }
`

const StadiumBodyStyles = styled.section`
  background-color: #fff;
  /* > section {
    text-align: center;

    color: var(--darkGrey);
    max-width: var(--maxWidth);
    .placeholder {
      position: relative;
      z-index: 999;
      padding: 3rem 0;
      font-size: 1.2rem;
      max-width: 700px;
      margin: 0 auto;
      @media ${media.md} {
        font-size: 1.5rem;
        padding-top: 0;
      }
    }
    .wrapper {
      position: relative;
      z-index: 5;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
    }
  } */
  .stripes {
    position: relative;
    width: 100%;
    height: 5rem;
    overflow-y: visible;
    /* margin-top: 3rem; */

    #stripes {
      position: absolute;
      z-index: 0;
      bottom: 0rem;
      left: -25rem;
      width: 35rem;
      @media ${media.ls} {
        width: 35rem;
        left: -20rem;
      }
      @media ${media.sm} {
        width: 35rem;
        left: -25rem;
      }
      @media ${media.lg} {
        width: 45rem;
        left: -31rem;
      }
      @media ${media.xl} {
        width: 50rem;
        left: -31rem;
      }
    }
  }
  .cross {
    position: relative;
    width: 100%;
    height: 5rem;
    overflow-y: visible;
    display: none;
    @media ${media.sm} {
      display: block;
    }
    #yellowX {
      position: absolute;
      top: 1rem;
      right: -5rem;
      //     width: 12rem;
      @media ${media.ls} {
        right: -2rem;
      }
      @media ${media.sm} {
        width: 13rem;
        right: 0rem;
        top: 0.5rem;
      }
      @media ${media.lg} {
        right: 2rem;
        top: 2rem;
        width: 14rem;
      }
      @media ${media.xl} {
        right: 6rem;
      }
    }
  }
  .venues {
    padding: 4rem 0 15rem 0;
    position: relative;
    z-index: 10;
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`
const SingleStadiumStyles = styled.section`
  width: 100%;
  cursor: pointer;
  max-width: 900px;
  margin: 0 auto;
  .img {
    position: relative;
    .gatsby-image-wrapper {
      border-radius: 5px;
      height: 15em;
      max-height: 507px;
      @media ${media.s} {
        height: 18rem;
      }
      @media ${media.ls} {
        height: 20rem;
      }
      @media ${media.md} {
        height: 25rem;
      }
      @media ${media.lg} {
        height: auto;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      border-radius: 5px;

      section {
        padding: 1rem;
        .wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          .icon {
            width: 25px;
            height: 25px;
            border: solid 2px rgba(255, 255, 255, 0.85);
            border-radius: 50px;
            transition: all 0.25s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              transition: all 0.25s ease-in-out;
              fill: rgba(255, 255, 255, 0.85);
              width: 12px;
              height: 12px;
            }
          }
        }
        p {
          text-transform: uppercase;
          font-weight: 700;
          line-height: 1.25;
          text-align: left;
          color: var(--white);
        }
        @media ${media.md} {
          p {
            font-size: 1.5rem;
          }
        }
      }
    }
    .hover {
      transition: var(--transition);
      opacity: 0;
      visibility: hidden;
      backdrop-filter: blur(20px);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      text-align: center;
      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.5rem;
        width: 90%;

        li {
          width: 240px;
          margin: 0 auto;
          background-color: var(--yellow);
          padding: 0.7rem;
          font-size: 0.7rem;
          font-weight: 900;
          text-transform: uppercase;
          transition: var(--transition);
          &:hover,
          &:active {
            background-color: #d41568;
            color: #ffffff;
            a {
              color: var(--white);
            }
          }
          a {
            transition: all 0.25s ease-in-out;
            color: var(--black);
          }
          @media ${media.s} {
            font-size: 0.9rem;
            width: 300px;
          }
        }
      }
    }
  }
  &:hover {
    .hover {
      opacity: 1;
      visibility: visible;
    }
  }
  &:nth-child(1) {
    .img {
      .overlay {
        background-color: rgba(0, 163, 224, 0.3);
      }
    }
  }
  &:nth-child(2) {
    .img {
      .overlay {
        background-color: rgba(135, 24, 157, 0.3);
      }
    }
  }
  &:nth-child(3) {
    .img {
      .overlay {
        background-color: rgba(0, 180, 187, 0.3);
      }
    }
  }
`
const StadiumBody = ({ data }) => (
  <FixBody>
    <div className="cross">
      <YellowX id="yellowX" />
    </div>
    <StadiumBodyStyles>
      <div className="venues">
        {data.map((venue, i) => (
          <SingleStadium data={venue} key={i} />
        ))}
      </div>
    </StadiumBodyStyles>
    <div className="stripes">
      <Stripes id="stripes" />
    </div>
  </FixBody>
)

export default StadiumBody

const SingleStadium = ({ data }) => {
  const [popup, setPopup] = useState(false)
  useEffect(() => {
    if (popup) {
      document.body.style.overflow = 'hidden'
    }
    if (!popup) {
      document.body.style.overflow = 'auto'
    }
  }, [popup])
  return (
    <SingleStadiumStyles>
      <div className="img">
        <GetImage data={data.mainImage} alt={data.name} />
        <div className="overlay">
          <section>
            <p>{`Matchday ${data.index}`}</p>
            <div className="wrapper">
              <p>{data.name}</p>
              <div className="icon">
                <ArrowIcon />
              </div>
            </div>
          </section>
        </div>
        <div className="hover">
          <ul>
            {data.links && (
              <>
                {data.links.map((link, i) => (
                  <li key={i}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </>
            )}
            {/* <li type="button" onClick={() => setPopup(true)}>
              Stadium Map
            </li> */}
          </ul>
        </div>
      </div>
      {/* {popup ? <StadiumPopUp data={data.map} setPopup={setPopup} /> : ''} */}
    </SingleStadiumStyles>
  )
}
