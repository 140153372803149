import GetImage from 'components/functional/getImage'
import React from 'react'
import styled from 'styled-components'
import CloseIcon from 'svgs/single-cross.svg'

const StadiumPopUpStyles = styled.section`
  animation: fade-in 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  z-index: 9999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  section {
    animation: slide-in-bottom 0.5s 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    width: 90%;
    margin: 0 auto;
    max-width: var(--maxWidth);
    text-align: center;

    .gatsby-image-wrapper {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 5px;
    }
  }
  .close {
    position: absolute;
    top: 1.25rem;
    right: 1rem;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
`
const StadiumPopUp = ({ data, setPopup }) => {
  const i = true
  console.log(data)
  return (
    <StadiumPopUpStyles>
      <section>
        <GetImage data={data} />
      </section>
      <CloseIcon onClick={() => setPopup(false)} className="close" />
    </StadiumPopUpStyles>
  )
}

export default StadiumPopUp
